import React, {Component} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button";
import ArrowNavigationIcon from '@material-ui/icons/NearMe'
import WaveSvg from "../assets/images/Wave.svg";
import {graphql, navigate} from 'gatsby';
import {isValidContentfulImage, isValidEmail, isValidSectionField} from "../common/utils/CommonUtils";
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import "./detail-common-style.scss";
import BreadCrumbs from "../components/bread-crumbs";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started/",
    },
    {
        title: "How To Guides",
        link: "/how-to-guides/",
    },
    {
        title: "All Courses",
        link: "/how-to-guides/mental-health-and-substance-use/",
    },

]

export default class CourseDtails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            success: false,
            error: false,
            isExpanded: false
        };
        this.pageData = props.data.contentfulCourseDetailPage;
    }

    startCourse(event, email) {
        event.preventDefault();
        const courseName = this.pageData.topSection.title;
        const {branchLink} = this.pageData;
        if (isValidEmail(email)) {
            SegmentAnalytics.track(SegmentEvents.USER_REQUESTED_FOR_COURSE, {
                email, courseName, branchLink,
                appRequested: true,
                category: 'Goal Completion',
                label: 'Email'
            }).then(()=>{
                navigate(`/courses-get-app/${this.pageData.slug}/`);
            });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': courseName,
                'event': 'START_COURSE',
                'email': email
            });

            this.setState({success: true, error: false, email: ""});


        } else {
            console.log("this.state.error", this.state.error);
            this.setState({
                error: true,
                success: false,
                email: ''
            });
        }
    };

    render() {
        const {topSection, benefits, courseOverview, courseBullets} = this.pageData;
        return (
            <Layout>
                <SEO title="Self-Directed Course Overview"/>
                <BreadCrumbs currentPage={this.pageData.topSection.title}  breadCrumbsList={breadCrumbsList}/>
                <div className="common-detailed-header">
                    <div className="common-detailed-inner course-detailed">
                        <div
                            className="common-detailed-pattern-content course-detailed">
                            {
                                isValidSectionField(topSection, "title") &&
                                <h2 className="common-detailed-main-head">{topSection.title}</h2>
                            }
                            {
                                isValidSectionField(topSection, "description")
                                &&
                                <p className="common-detailed-sub-head"
                                   dangerouslySetInnerHTML={{__html: topSection.description.description}}>
                                </p>
                            }
                            <form method="post"
                                  onSubmit={(event) => this.startCourse(event,
                                      this.state.email)}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="phone-input"
                                            className="number-field"
                                            placeholder="Enter your email"
                                            value={this.state.email}
                                            margin="normal"
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    email: value,
                                                    error: false,
                                                    success: false,
                                                })
                                            }}
                                        />
                                    </div>
                                    <Button
                                        className="blue-arrow-btn"
                                        color="primary"
                                        type="submit"
                                    >
                                        Start Course <ArrowNavigationIcon/>
                                    </Button>
                                </div>
                            </form>
                            {this.state.error ? <p
                                className="course-detail-error-msg">Please Enter
                                Valid Email</p> : null}
                            {this.state.success ? <p
                                className="course-detail-success-msg">Subscribed
                                Successfully </p> : null}
                        </div>
                        <div className="common-detailed-video-side">
                            <div className="common-detailed-video-inner">
                                <div className="video-wrapper">
                                    {topSection.imageOrVideo !== null
                                    && topSection.imageOrVideo.file.contentType.includes(
                                        "image") ?
                                        <img src={'https:'
                                        + topSection.imageOrVideo?.file?.url}
                                             alt="CourseImage"/>
                                        :
                                        <img src={require(
                                            '../assets/images/courses-video.png')}
                                             alt="CourseImage"/>
                                    }
                                </div>
                                <div className="course-info-wrapper">
                                    {
                                        topSection.courseMeta
                                        && topSection.courseMeta.map(meta => (
                                            <div className="single-course-info">
                                                {
                                                    isValidContentfulImage(
                                                        meta.image) && (
                                                        <img
                                                            className="info-icon"
                                                            src={meta.image.file.url}
                                                            alt="Icon"/>
                                                    )
                                                }
                                                <div className="info-content">
                                                    {
                                                        isValidSectionField(
                                                            meta, "name") &&
                                                        <p className="title">{meta.name}</p>
                                                    }
                                                    {
                                                        isValidSectionField(
                                                            meta, "description")
                                                        &&
                                                        <p className="des">{meta.description}{meta.name
                                                        === "CHAPTERS"
                                                            ? "Chapters"
                                                            : ""}</p>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="common-detailed-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <div className="wave-bg">
                        <div className="common-detailed-feature-inner">
                            {
                                benefits && benefits.map(benefit => (
                                    <div
                                        className="single-common-detailed-feature">
                                        {
                                            isValidContentfulImage(
                                                benefit.image) && (
                                                <img
                                                    className="single-common-detailed-img"
                                                    src={benefit.image.file.url}
                                                    alt="Icon"/>
                                            )
                                        }
                                        <div
                                            className="common-detailed-feature-content">
                                            {
                                                isValidSectionField(benefit,
                                                    "name") &&
                                                <h2 className="head">{benefit.name}</h2>
                                            }
                                            {
                                                isValidSectionField(benefit,
                                                    "description") &&
                                                <p className="para"
                                                   dangerouslySetInnerHTML={{__html: benefit.description}}></p>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="course-descr-wrapper">
                    <div className="course-descr-inner">
                        <div className="course-descr-left-side">
                            {
                                isValidSectionField(courseOverview, "title") &&
                                <h2 className="course-descr-head">{courseOverview.title}</h2>
                            }
                            {
                                isValidSectionField(courseOverview,
                                    "description") &&
                                <p className="course-descr-para"
                                   dangerouslySetInnerHTML={{__html: courseOverview.description.description}}></p>
                            }
                        </div>
                        <div className="course-descr-right-side">
                            {
                                isValidSectionField(courseBullets, "title") &&
                                <h2 className="course-descr-head">{courseBullets.title}</h2>
                            }
                            <ul>
                                {
                                    courseBullets.bulletPoints
                                    && courseBullets.bulletPoints.map(
                                        bullet => (
                                            <li>
                                                <img
                                                    src={require(
                                                        '../assets/images/checkmark-circle-outlined.svg')}
                                                    alt="icon"/>

                                                <p>{bullet}</p>

                                            </li>
                                        ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql` 
query contentfulPublicCoursesBySlug($slug:String) {
 contentfulCourseDetailPage(slug: {eq: $slug}) {
    id
    slug
    topSection {
      title
      ... on ContentfulPageTopSection {
      description {
        description
      }
      }
      courseMeta {
        name
        ... on ContentfulSectionMeta{
        description
        image {
          file {
            contentType
            url
          }
        }
        }
      }
      imageOrVideo {
        file {
          contentType
          url
        }
      }
    }
    benefits {
      name
      ... on ContentfulSectionMeta{
      description
      image {
        file {
          url
          contentType
        }
      }
      }
    }
    courseOverview {
      title
      ... on ContentfulBenefitOverview {
      description {
        description
      }
      }
    }
    courseBullets {
      bulletPoints
      title
    }
    ... on ContentfulCourseDetailPage {
            branchLink
    }
 }
}`;


